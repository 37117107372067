import { useRef } from 'react';
import { type DecoratedConversationUser } from '@witmetrics/api-client';
import ListItem from '@mui/material/ListItem';
import TruncatedText from '@/components/TruncatedText';
import ProfilePicture from '@/components/ProfilePicture';
import OptionsIcon from '@/icons/OptionsIcon';
import OptionsMenu from '../OptionsMenu';
import { useToggle } from '@/hooks/useToggle';
import { useCurrentUser } from '@/store/useStore';
import {
  classes,
  isAdmin,
  renderName,
  styles,
  toggleAdminStatus,
} from './utils';

export type MembersListItemProps = {
  showOptions: boolean;
  showAdminOption: boolean;
  member: DecoratedConversationUser;
  onUpdate: (userID: number, userConversationStatusID: number) => void;
  onRemove: (userID: number) => void;
};

// TODO: Actual profile pictures instead of just initials
export default function MembersListItem({
  showOptions,
  showAdminOption,
  member,
  onUpdate,
  onRemove,
}: MembersListItemProps) {
  const menuToggleRef = useRef(null);
  const currentUser = useCurrentUser();
  const [isMenuOpen, toggleMenu] = useToggle(false);

  const handleChangeAdminStatus = () => {
    onUpdate(member.user.id, toggleAdminStatus(member));
    toggleMenu(false);
  };

  const handleRemoveMember = () => {
    onRemove(member.user.id);
    toggleMenu(false);
  };

  return (
    <>
      <ListItem
        disablePadding
        sx={styles.listItem}
        className={classes.listItem}>
        <div className={classes.userInfo}>
          <div className={classes.avatarWrapper}>
            <ProfilePicture
              className={classes.avatar}
              size={30}
              user={{ ...member.user, userPreferences: null }}
            />
          </div>
          <TruncatedText
            className={classes.nameWrapper}
            textClassName={classes.name}>
            {renderName(member)}
          </TruncatedText>
        </div>
        {showOptions && (
          <OptionsIcon
            ref={menuToggleRef}
            className={classes.optionsIcon}
            onClick={() => toggleMenu()}
          />
        )}
      </ListItem>
      {isMenuOpen && (
        <OptionsMenu
          open
          isSelf={member.user.id === currentUser!.id}
          showAdminOption={showAdminOption}
          isAdmin={isAdmin(member)}
          anchorTo={menuToggleRef.current}
          onChangeAdminStatus={handleChangeAdminStatus}
          onRemove={handleRemoveMember}
          onClose={() => toggleMenu(false)}
        />
      )}
    </>
  );
}
