import { useEffect, useState } from 'react';
import {
  useActiveAccount,
  useConversationUsers,
  useDispatch,
} from '@/store/useStore';
import { type UndecoratedUser } from '@witmetrics/api-client';
import { setConversationUsers as dispatchSetConversationUsers } from '@/store/slices/conversationUsersSlice';
import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import List from '@mui/material/List';
import ListItem from './ListItem';
import AddIcon from '@/icons/AddIcon';
import DeleteIcon from '@/icons/DeleteIcon';
import SearchField from '@/components/SearchField';
import Divider from '@mui/material/Divider';
import LoopingEllipsis from '@/components/LoopingEllipsis';
import { showSuccessToaster } from '@/utils/toasters';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import { isEmpty } from '@/utils/arrays';
import {
  classes,
  fetchPracticeUsers,
  getAvailableUsers,
  saveNewMembers,
  searchUsers,
} from './utils';

export type ConversationMembersDialogProps = {
  conversationID: number;
  onClose: () => void;
};

export default function ConversationMembersDialog({
  conversationID,
  onClose,
}: ConversationMembersDialogProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const activeAccount = useActiveAccount();
  const members = useConversationUsers(conversationID);
  const [newMembers, setNewMembers] = useState<UndecoratedUser[]>([]);
  const [practiceUsers, setPracticeUsers] = useState<UndecoratedUser[]>([]);
  const [search, setSearch] = useState('');
  const [isFetchingData, toggleFetchingData] = useToggle(false);
  const [isSaving, toggleSaving] = useToggle(false);

  useEffect(() => {
    if (activeAccount?.accountID) handleFetchUsers();
  }, []);

  const handleFetchUsers = async () => {
    try {
      toggleFetchingData(true);
      const users = await fetchPracticeUsers(activeAccount!.accountID);
      setPracticeUsers(users);
      toggleFetchingData(false);
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error fetching users', () => handleFetchUsers());
    }
  };

  const handleSave = async () => {
    try {
      toggleSaving(true);
      const users = await saveNewMembers(conversationID, newMembers);
      dispatch(dispatchSetConversationUsers(users));
      showSuccessToaster('Members added');
      onClose();
    } catch (err) {
      toggleSaving(false);
      onApiError(err, 'Error adding members');
    }
  };

  const handleAddMember = (user: UndecoratedUser) => {
    setNewMembers([user, ...newMembers]);
  };

  const handleRemoveMember = (userID: number) => {
    setNewMembers(newMembers.filter((user) => user.id !== userID));
  };

  const isSaveDisabled = () => {
    return isSaving || isEmpty(newMembers);
  };

  const renderSelected = () => {
    if (isEmpty(newMembers)) {
      return <div className={classes.message}>No users selected.</div>;
    }
    return (
      <List className={classes.list}>
        {newMembers.map((user) => (
          <ListItem
            key={user.id}
            user={user}
            icon={<DeleteIcon />}
            onClick={() => handleRemoveMember(user.id)}
          />
        ))}
      </List>
    );
  };

  const renderFetching = () => {
    return (
      <div className={classes.message}>
        Fetching users
        <LoopingEllipsis />
      </div>
    );
  };

  const renderOptions = () => {
    if (isFetchingData) return renderFetching();
    if (isEmpty(practiceUsers)) {
      return <div className={classes.message}>No available users.</div>;
    }
    let users = getAvailableUsers(practiceUsers, members, newMembers);
    if (isEmpty(users)) {
      return <div className={classes.message}>All users selected.</div>;
    }
    users = searchUsers(users, search);
    if (isEmpty(users)) {
      return (
        <div className={classes.message}>No users matching current search.</div>
      );
    }
    return (
      <List className={classes.list}>
        {users.map((user) => (
          <ListItem
            key={user.id}
            user={user}
            icon={<AddIcon />}
            onClick={() => handleAddMember(user)}
          />
        ))}
      </List>
    );
  };

  return (
    <Dialog className={classes.dialog} onClose={onClose}>
      <DialogTitle title="Add members" onClose={onClose} />
      <DialogBody className={classes.body}>
        <div className={classes.title}>Selected</div>
        {renderSelected()}
        <Divider className={classes.divider} />
        <div className={classes.title}>Options</div>
        <SearchField
          className={classes.search}
          value={search}
          onChange={(update) => setSearch(update)}
        />
        {renderOptions()}
      </DialogBody>
      <DefaultDialogActions
        disabled={isSaveDisabled()}
        pending={isSaving}
        confirmLabel="Add"
        pendingLabel="Adding..."
        onCancel={onClose}
        onConfirm={handleSave}
      />
    </Dialog>
  );
}
