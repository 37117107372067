export const classes = {
  wrapper: 'flex justify-center',
  buttonWrapper: 'flex flex-col items-center',
  footerText: 'text-grey-7 text-sm',
  button: 'mb-3 py-1',
};

export const styles = {
  footerText: (isFetching: boolean) => ({ opacity: isFetching ? 0 : 1 }),
};
