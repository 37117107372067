import List, { type ListProps } from '@mui/material/List';
import type { DecoratedConversation } from '@witmetrics/api-client';
import ConversationsListItem from './ConversationsListItem';

export interface ConversationsListProps extends Omit<ListProps, 'onSelect'> {
  isFetchingData: boolean;
  conversations: DecoratedConversation[];
  onSelect: (conversationID: number) => void;
}

export default function ConversationsList({
  isFetchingData,
  conversations,
  onSelect,
  ...props
}: ConversationsListProps) {
  return (
    <List {...props}>
      {conversations.map((conversation) => (
        <ConversationsListItem
          key={conversation.id}
          conversation={conversation}
          onSelect={onSelect}
        />
      ))}
    </List>
  );
}
