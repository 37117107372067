import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import TruncatedText from '@/components/TruncatedText';
import type { DecoratedConversation } from '@witmetrics/api-client';
import {
  useConversationUsers,
  useDateFormatPreference,
} from '@/store/useStore';
import { classes, getSubjectString, getUpdatedAtString, styles } from './utils';

export type ConversationsListItemProps = {
  conversation: DecoratedConversation;
  onSelect: (conversationID: number) => void;
};

export default function ConversationsListItem({
  conversation,
  onSelect,
}: ConversationsListItemProps) {
  const dateFormat = useDateFormatPreference();
  const members = useConversationUsers(conversation.id);

  // TODO: Include a preview of the most recent message (e.g. "Sean: Hey, how's it going?")

  return (
    <ListItem disablePadding>
      <ListItemButton
        sx={styles.listItemButton}
        className={classes.listItemButton}
        onClick={() => onSelect(conversation.id)}>
        <div className={classes.titleRow}>
          <TruncatedText>
            {getSubjectString(conversation.subject, members)}
          </TruncatedText>
          <div className={classes.date}>
            {getUpdatedAtString(conversation.updatedAt, dateFormat)}
          </div>
        </div>
      </ListItemButton>
    </ListItem>
  );
}
