import {
  type DecoratedConversationUser,
  type UndecoratedUser,
  USER_CONVERSATION_STATUS_ID,
} from '@witmetrics/api-client';
import { isEmpty } from '@/utils/arrays';
import { API } from '@/api';

const { ADMIN, CREATOR, MEMBER } = USER_CONVERSATION_STATUS_ID;

export const ADMIN_SELECT_MODE = {
  REPLACING: 'REPLACING',
  LEAVING: 'LEAVING',
} as const;

export const classes = {
  accordion: 'px-1 m-0 shadow-none',
  header: 'px-0',
  wrapper: 'w-full',
  addWrapper: 'w-full flex justify-center items-center',
  // header: 'flex justify-between items-center pl-2',
  addButton: 'normal-case font-semibold',
  title: 'font-semibold text-sm text-grey-8 px-3',
};

export function checkIsAdmin(
  user: UndecoratedUser,
  members: DecoratedConversationUser[]
) {
  const member = members.find((m) => m.user.id === user.id)!;
  if (!member) return false;
  return [ADMIN, CREATOR].includes(member.userConversationStatusID);
}

export function checkLeavingMember(
  userID: number,
  members: DecoratedConversationUser[]
) {
  const updatedMembers = members.filter((m) => m.user.id !== userID);
  const remainingAdmins = updatedMembers.filter((m) =>
    [CREATOR, ADMIN].includes(m.userConversationStatusID)
  );
  return {
    isLastMember: isEmpty(updatedMembers),
    isLastAdmin: isEmpty(remainingAdmins),
  };
}

export function saveNewAdmins(conversationID: number, adminIDs: number[]) {
  return Promise.all(
    adminIDs.map((userID) =>
      API.Conversations.updateConversationUser(conversationID, userID, {
        userConversationStatusID: ADMIN,
      })
    )
  );
}

export function updateAdmins(
  conversationID: number,
  memberID: number,
  adminIDs: number[]
) {
  return Promise.all([
    API.Conversations.updateConversationUser(conversationID, memberID, {
      userConversationStatusID: MEMBER,
    }),
    ...adminIDs.map((userID) =>
      API.Conversations.updateConversationUser(conversationID, userID, {
        userConversationStatusID: ADMIN,
      })
    ),
  ]);
}

export function leaveConversation(conversationID: number, userID: number) {
  return API.Conversations.removeConversationUser(conversationID, userID);
}
