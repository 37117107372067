import { twMerge } from 'tailwind-merge';
import LoopingEllipsis from '@/components/LoopingEllipsis';
import Button from '@/components/Button';
import type { Pagination } from '@/utils/filters';
import { classes, styles } from './utils';

export type TablePaginationProps = {
  className?: string;
  isFetching: boolean;
  labels?: [string, string];
  pagination: Pagination;
  rowCount: number;
  onFetch: () => void;
};

export default function TablePagination({
  className = '',
  isFetching,
  labels = ['row', 'rows'],
  pagination,
  rowCount,
  onFetch,
}: TablePaginationProps) {
  return (
    <div className={twMerge(classes.wrapper, className)}>
      {pagination.page === pagination.pageCount ? (
        <div className={classes.footerText}>{`End of results (${rowCount} ${
          rowCount === 1 ? labels[0] : labels[1]
        })`}</div>
      ) : (
        <div className={classes.buttonWrapper}>
          {rowCount === 0 ? null : (
            <Button
              variant="outlined"
              capitalized={false}
              disabled={isFetching}
              className={classes.button}
              onClick={onFetch}>
              {isFetching ? (
                <span>
                  Fetching
                  <LoopingEllipsis />
                </span>
              ) : (
                `Fetch more ${labels[1]}`
              )}
            </Button>
          )}
          <div
            style={styles.footerText(isFetching)}
            className={classes.footerText}>
            {`Showing ${rowCount} out of ${pagination.rowCount} ${
              rowCount === 1 ? labels[0] : labels[1]
            }`}
          </div>
        </div>
      )}
    </div>
  );
}
