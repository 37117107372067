import { useEffect, useState } from 'react';
import ConversationMessages from './ConversationMessages';
import MessageComposer from './MessageComposer';
import ConversationInfo from './ConversationInfo';
import ConversationHeader from './ConversationHeader';
import LoopingEllipsis from '@/components/LoopingEllipsis';
import { useToggle } from '@/hooks/useToggle';
import { useAppState } from '@/providers/AppStateProvider';
import { useConversationMessages, useDispatch } from '@/store/useStore';
import { addUnisonProject as dispatchAddUnisonProject } from '@/store/slices/unisonProjectsSlice';
import { addConversation as dispatchAddConversation } from '@/store/slices/conversationsSlice';
import { setConversationMessages as dispatchSetConversationMessages } from '@/store/slices/conversationMessagesSlice';
import { setConversationUsers as dispatchSetConversationUsers } from '@/store/slices/conversationUsersSlice';
import { setFileCollections as dispatchSetFileCollections } from '@/store/slices/fileCollectionsSlice';
import { setFileCollectionFiles as dispatchSetFileCollectionFiles } from '@/store/slices/fileCollectionFilesSlice';
import { useSockets } from '@/providers/SocketsProvider';
import { SOCKET_EVENTS } from '@/constants/socketEvents';
import { classes, conversationSocket, fetchConversationData } from './utils';

export type ConversationProps = {
  conversationID: number;
  onClose: () => void;
};

export default function Conversation({
  conversationID,
  onClose,
}: ConversationProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const { send, join, leave } = useSockets();
  const [isFetchingData, toggleFetchingData] = useToggle(true);
  const [isInfoOpen, toggleInfo] = useToggle(true);
  const [projectID, setProjectID] = useState<number | null>(null);
  const messages = useConversationMessages(conversationID);

  useEffect(() => {
    if (conversationID) {
      fetchData();
      join({ endpoint: conversationSocket(conversationID) });
      document.addEventListener('visibilitychange', handleVisibilityChange);
    } else {
      toggleFetchingData(false);
    }
    return () => {
      leave(conversationSocket(conversationID));
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    // When a new message comes in, update lastViewedAt if convo is open & visible
    if (!document.hidden) updateLastViewedAt();
  }, [messages]);

  const fetchData = async () => {
    try {
      toggleFetchingData(true);
      const data = await fetchConversationData(conversationID!);
      setProjectID(data.unisonProject.id);
      // TODO: Store pagination for messages & users
      dispatch(dispatchAddConversation(data.conversation));
      dispatch(dispatchAddUnisonProject(data.unisonProject));
      dispatch(
        dispatchSetConversationMessages(data.conversationMessages.results)
      );
      dispatch(dispatchSetConversationUsers(data.conversationUsers.results));
      dispatch(dispatchSetFileCollections(data.fileCollections));
      dispatch(dispatchSetFileCollectionFiles(data.files));
      toggleFetchingData(false);
      updateLastViewedAt(); // Broadcast that the user has opened the conversation
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error fetching conversation', () => fetchData());
    }
  };

  const handleVisibilityChange = () => {
    if (!document.hidden) updateLastViewedAt();
  };

  const updateLastViewedAt = () => {
    send(SOCKET_EVENTS.OPEN_CONVERSATION, { conversationID });
  };

  if (isFetchingData) {
    return (
      <div className={classes.wrapper}>
        <div className={classes.fetching}>
          Fetching conversation
          <LoopingEllipsis />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.leftColumn}>
        <ConversationHeader
          infoOpen={isInfoOpen}
          conversationID={conversationID}
          onOpenInfo={() => toggleInfo(true)}
          onClose={onClose}
        />
        <ConversationMessages conversationID={conversationID} />
        {projectID && (
          <MessageComposer
            projectID={projectID}
            conversationID={conversationID}
          />
        )}
      </div>
      {isInfoOpen && (
        <div className={classes.rightColumn}>
          <ConversationInfo
            conversationID={conversationID}
            onLeaveConversation={onClose}
            onClose={() => toggleInfo(false)}
          />
        </div>
      )}
    </div>
  );
}
