import Menu, { type MenuProps } from '@/components/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddFileIcon from '@/icons/AddFileIcon';
import AddFolderIcon from '@/icons/AddFolderIcon';
import FileInput from '@/components/FileInput';
import DirectoryInput from '@/components/DirectoryInput';
import { type Directory } from '@/utils/files';
import { classes } from './utils';

export type AttachmentMenuProps = {
  open: boolean;
  hasProject: boolean;
  anchorTo: HTMLElement | null;
  anchorOrigin?: MenuProps['anchorOrigin'];
  transformOrigin?: MenuProps['transformOrigin'];
  onAddFiles: (files: File[]) => void;
  onAddDirectory: (directory: Directory) => void;
  onSelectFromProject: () => void;
  onClose: () => void;
};

export default function AttachmentMenu({
  open,
  hasProject,
  anchorTo,
  anchorOrigin,
  transformOrigin,
  onAddFiles,
  onAddDirectory,
  onSelectFromProject,
  onClose,
}: AttachmentMenuProps) {
  return (
    <Menu
      open={open}
      anchorTo={anchorTo}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}>
      <FileInput
        multiple
        id="new-files"
        className={classes.input}
        onSelect={onAddFiles}
      />
      <DirectoryInput
        multiple
        id="new-directories"
        className={classes.input}
        onSelect={onAddDirectory}
      />
      {/*hasProject && (
        <li className={classes.menuItem} onClick={onSelectFromProject}>
          <ListItemIcon>
            <AddFolderIcon />
          </ListItemIcon>
          <ListItemText>Select from project</ListItemText>
        </li>
      )*/}
      <li className={classes.menuItem}>
        <label htmlFor="new-files" className={classes.inputLabel}>
          <ListItemIcon>
            <AddFileIcon />
          </ListItemIcon>
          <ListItemText>Upload File(s)</ListItemText>
        </label>
      </li>
      <li className={classes.menuItem}>
        <label htmlFor="new-directories" className={classes.inputLabel}>
          <ListItemIcon>
            <AddFolderIcon />
          </ListItemIcon>
          <ListItemText>Upload File Collection(s)</ListItemText>
        </label>
      </li>
    </Menu>
  );
}
