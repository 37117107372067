export const classes = {
  summary: 'pl-4 pr-6',
  summaryText: 'font-bold text-base text-grey-8',
  expandIcon: 'text-3xl',
};

export const styles = {
  accordion: {
    '&:before': { display: 'none' },
    '& .MuiAccordionDetails-root': { padding: 0 },
  },
};
